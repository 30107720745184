<template>
  <div class="not-found">
    <div class="not-found__conteiner">
      <h1 class="not-found__conteiner_title">
        Error 404. The page you are looking for is not available.
      </h1>
      <BaseButton
        class="not-found__conteiner_button button--white button--ellipse button--lg"
        text="Back to Home"
        @click.native="goToPage('Home')"
      />
    </div>
    <img
      class="not-found__image"
      v-webp="require('@/assets/images/404.png')"
      src="@/assets/images/404.png"
      alt="Grotta House plan"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: { BaseButton },

  data() {
    return {};
  },

  computed: {},

  methods: {
    goToPage(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;

  &__conteiner {
    width: 38%;
    padding: 20rem 0rem 0rem 12.4rem;
    @media only screen and (max-width: $lg) {
      width: 75%;
      padding: 15rem 0rem 0rem 12.4rem;
    }
    @media only screen and (max-width: $xs) {
      width: 100%;
      padding: 16rem 4.4rem 0rem;
    }

    &_title {
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 6.8rem;
      color: $white;
      @media only screen and (max-width: $xs) {
        font-size: 2.8rem;
        letter-spacing: -0.47px;
        line-height: 1.42;
      }
    }

    &_button {
      width: max-content;
      font-size: 2.1rem;
      line-height: 3.2rem;
      padding: 18px 27px;
      margin-top: 6rem;
    }
  }

  &__image {
    position: absolute;
    height: auto;
    width: 50%;
    max-width: max-content;
    right: 0;
    bottom: 0;
    @media only screen and (max-width: $lg) {
      width: 75%;
    }
    @media only screen and (max-width: $xxs) {
      width: 130%;
      right: -40%;
    }
  }
}
</style>
